// const images = require.context('../images', true);

import 'bootstrap'

import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';

import '../stylesheets/application'

require("@rails/ujs").start();
// require("channels");
